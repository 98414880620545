.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5%;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out; /* Fade-in animation */
}

.lightbox-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 25%;
  max-width: 500px;
  position: relative;
  top: 10%;
  right: 2%;
  animation: slideIn 0.3s ease-out; /* Slide-in animation */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: red;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  margin: 10px 0;
}

nav ul li a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

/* FONTS */

@font-face {
  font-family: romia;
  src: url("./Romla.ttf");
}
@font-face {
  font-family: rena;
  src: url("./Rena-Regular.ttf");
}
/* SCROLLBAR */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background: #bab9b9a0; /* Adjust the background color as needed */
}

::-webkit-scrollbar-thumb {
  background: rgba(101, 101, 101, 0.762); /* Transparent thumb */
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.805); /* Transparent thumb */
}

/* UNIVERSAL */
* button:hover {
  cursor: pointer;
}

.example::-webkit-scrollbar {
  display: none;
}
* h1,
* h3,
* h2,
* h4 {
  font-weight: 50;
}
/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* NAVIGATION BAR */

.navBg {
  background-color: transparent;
  color: white;
  position: relative;
  text-align: center;
  line-height: 3vh;
  margin: 5px;
  z-index: 999;
  top: 25%;
}
.navBg h1 {
  margin: 0;
  margin-bottom: 2.5%;
  font-size: 4.3vw;
  font-family: romia;
  text-transform: uppercase;
  margin-bottom: 3vw;
}
.navBg p {
  font-size: 1.3vw;
  font-family: rena;
}

nav {
  font-family: rena;
  background-color: transparent;
  padding: 3% 5% 2.5% 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.9vw;
  margin: 0;
  z-index: 999;
}
/* VIDEO BACKGROUND */
.video-background {
  position: relative;
  height: fit-content;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 2%;
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

nav a:hover {
  transform: scale(1.2);
  color: rgb(198, 102, 34);
}

.nav-links {
  width: 40%;
  text-transform: uppercase;
}
.logo {
  width: 15vw;
  position: relative;
  bottom: 20px;
}

#nav1 {
  display: flex;
  justify-content: space-evenly;
  gap: 5%;
}
#nav2 {
  display: flex;
  justify-content: space-evenly;
  gap: 5%;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-icons {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.nav-icons a img {
  width: 1.5vw;
}
.nav-icons a img:hover {
  transform: scale(1.2);
}

.navBtn {
  display: flex;
  justify-content: center;
  gap: 2%;
}
.navBtn button {
  font-family: rena;
  background-color: transparent;
  border: 1px white solid;
  font-size: 0.7vw;
  color: white;
  text-transform: uppercase;
  width: 8%;
  height: 2.4vw;
  border-radius: 0.5vw;
  cursor: pointer;
  border: 0.1vw solid;
}
.navBtn > button:first-child {
  color: white;
  background-color: rgb(19, 18, 18);
  border: 1px solid rgb(19, 18, 18);
}
.navBtn > button:first-child:hover {
  background-color: rgba(161, 161, 161, 0.055);
  border: 1px solid white;
}
.navBtn > button:last-child:hover {
  background-color: rgb(19, 18, 18);
  border: 1px solid rgb(19, 18, 18);
}

/* Phone NavBar */

.phoneNav {
  background-color: #000000;
  height: fit-content;
  padding: 1% 3% 2% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.phoneLogo {
  width: 14vw;
  height: 6vw;
}
#nav3Btn {
  width: 45px;
  height: 45px;
}

/* BODY */

body {
  margin: 0;
  padding: 0;
}
/* ABOUT */
.about {
  padding: 7%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about .info,
.about .abtImage {
  width: 40%;
  height: 34vw;
}
.about .info h3 {
  font-size: 2.8vw;
  font-family: romia;
  margin-top: 0;
  margin-bottom: 0;
}
.about .info p {
  font-family: rena;
  line-height: 2.5vw;
  font-size: 1vw;
}
.abtImage {
  background-color: rgb(41, 41, 44);
  width: 1px;
  height: 1px;
}
/* PRODCUTS */
.product {
  padding: 3% 8% 1% 8%;
  background-image: url("../img/newBg.png");
  background-size: cover;
  background-position: center;
}
.head {
  display: flex;
  justify-content: space-between;

  line-height: 10px;
  margin-bottom: 5%;
}
.head h3 {
  font-family: romia;
  font-size: 2.8vw;
}
.head p {
  font-family: rena;
  font-size: 1vw;
}

.head button {
  width: 8%;
  height: fit-content;
  padding: 1% 0% 1% 0%;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  font-size: 1vw;
}
.viewMore {
  width: 8%;
  padding: 1% 0% 1% 0%;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  position: relative;
  left: 93%;
  font-size: 1vw;
}
.head button:hover,
.viewMore:hover,
.items button:hover {
  background-color: #000000;
  color: white;
}
.products {
  width: 100%;
  line-height: 1px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.items {
  display: flex;
  flex-direction: column;
  width: 16vw;
  height: fit-content;
}
.products img {
  height: 14vw;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  left: 50%;
  position: relative;
  transform: translate(-50%);
  object-fit: cover;
}

.items h5 {
  font-family: romia;
  font-size: 0.7vw;
  margin-bottom: 0.3vw;
}
.items p {
  font-size: 0.8vw;
}
.items button {
  background-color: transparent;
  border: 1px solid black;
  width: 100%;
  height: 3vw;
  position: relative;
  left: 50%;
  font-size: 1vw;
  transform: translate(-50%);
}
.itemDetails {
  display: flex;
  justify-content: space-between;
}
.productRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5%;
}

/* TESTIMONY */
.testimony {
  background-color: #f5ece5;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0% 3% 3% 3%;
}
.testimony .head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 5px;
  margin-top: 5%;
  margin-bottom: 0;
}
.testimony .head h3 {
  font-family: romia;
  font-size: 2.8vw;
  margin-bottom: 2vw;
}
.testimony .head p {
  font-family: rena;
  font-size: 1vw;
}
.testimonies {
  width: 95%;
  height: 30vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0;
}
.Ctestimony {
  width: 20%;
  height: 50%;
  border: 1px solid black;
  padding: 5%;
}
.Ctestimony p {
  line-height: 2vw;
  margin-top: 0;
  font-size: 1vw;
}
.Ctestimony h3 {
  font-size: 1vw;
  margin-bottom: 0;
}
.Ctestimony .star {
  position: relative;
}
.Ctestimony .star img {
  width: 3vw;
  margin-bottom: 5%;
}
.testimonyUser {
  width: 3vw;
  height: 3vw;
}
.testifoot {
  display: flex;
  align-items: center;
  gap: 10%;
}
.scroll {
  width: fit-content;
  padding: 0% 2% 0% 2%;
  height: 1vw;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows img {
  width: 6vw;
  height: 6vw;
  cursor: pointer;
  position: absolute;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}
.arrows img:hover {
  transform: scale(1.1);
}

/* CONTACT */
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0% 7% 14%;
  height: 70vh;
  background-image: url("../img/newBg.png");
  background-size: cover;
  background-position: center;
}
.contact .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  line-height: 1px;
  margin-bottom: 2%;
}
.contact .head h3 {
  font-family: romia;
  font-size: 2.8vw;
}
.contact .head p {
  font-family: rena;
  font-size: 1vw;
}
.contact .body {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 5%;
}
.contact .body .form {
  width: 38%;
  display: flex;
  flex-direction: column; /* Ensure form elements stack vertically */
}

.ContactInput {
  height: 70px;
  width: 100% !important;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  padding: 15px;
  font-size: 1vw;
  margin-bottom: 1vw;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.contact .body .form textarea {
  height: 200px;
  width: 100%;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  padding: 15px;
  font-size: 1vw;
  margin-bottom: 20px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.contact .body .form button {
  width: 100%;
  height: 5vw;
  font-size: 1.1em;
  background-color: black;
  color: white;
  border: 1px solid black;
  font-family: rena;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.contact .body .form button:hover {
  background-color: transparent;
  color: black;
  cursor: pointer;
}
.contact .body .map {
  width: 40%;
  background-color: green;
}
.contact .body .map iframe {
  border: 1px solid black;
}
.contact ::placeholder {
  opacity: 1;
  color: black;
}
/* MAD HONEY */
.madHoney {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  padding: 5%;
  padding-bottom: 1%;
  margin-bottom: 5%;
}
.madHoney .productDetail h2 {
  font-size: 2vw;
  font-family: romia;
  margin-top: 0;
}
.productDesc h3 {
  font-size: 2vw;
  font-family: romia;
}
.madHoney h3,
.madHoney h4 {
  font-family: romia;
  font-size: 1.5vw;
}
.madHoney p {
  font-family: rena;
  font-size: 1vw;
}
.madHoney .productDetail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
  height: 25vw;
}
.madHoney .productDetail .head {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  line-height: 1px;
  margin-bottom: 0;
}
.productDetail .quantity img {
  width: 2vw;
  cursor: pointer;
}
.quantity .input {
  display: flex;
  align-items: center;
  gap: 2%;
}
.quantity input {
  text-align: center;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  font-size: 1vw;
  width: 5vw;
  text-align: center;
}
.weight button {
  font-family: rena;
  width: 80px;
  padding: 5px;
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  margin-right: 10px;
}
.weight button:hover {
  background-color: black;
  color: white;
}
#addToCart {
  font-family: rena;
  font-size: 1vw;
  width: 100%;
  height: 3vw;
  padding: 0.5vw;
  background-color: transparent;
  border: 1px solid black;
}
#addToCart:hover {
  background-color: black;
  color: white;
}
.productImage {
  width: 25vw;
  height: 25vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border: 1px solid black;
}
#futureUse {
  width: fit-content;
  font-family: rena;
  line-height: 3vw;
  font-size: 1vw;
  position: relative;
  left: 16.5%;
  padding: 0% 5% 0% 5%;
}
.productShow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
/* QUESTION */
.question {
  padding: 7%;
}
.questions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  margin-bottom: 2%;
}
.question .head {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  margin-bottom: 2%;
}

.questions .left {
  display: flex;
  align-items: center;
  gap: 8%;
}
.questions .left img {
  width: 3vw;
  height: 3vw;
}
.questions .left .details {
  line-height: 5px;
}
.questions .left .details p {
  font-size: 15px;
}
.questions .right p {
  font-size: 15px;
}
.qusBox {
  display: flex;
  gap: 5%;
  align-items: center;
  width: 95%;
}
.customer {
  border-bottom: 1px solid black;
}

/* CART */
.cart {
  padding: 5%;
}
.cart .item {
  display: flex;
  gap: 3%;
  margin-bottom: 2%;
  position: relative;
  left: 3%;
  width: 80vw;
  display: flex;
  gap: 5%;
  align-items: center;
}
#cancelItem {
  height: 2vw;
}
#cancelItem:hover {
  transform: scale(1.2);
}

.itemDesc {
  width: 100%;
  height: 12vw;
  background-color: rgb(174, 174, 174);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
}
.itemDesc input {
  width: 5%;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1vw;
  text-align: center;
}
.itemDesc img {
  width: 14%;
  height: 90%;
}
.productDesc {
  width: 40%;
  padding-bottom: 2%;
}
.productDesc h3 {
  margin-bottom: 0;
}
.cartHead {
  display: flex;
  align-items: center;
  gap: 47%;
  width: 73.6vw;
  position: relative;
  left: 10%;
  font-family: romia;
  bottom: 3vw;
  border-bottom: 1px solid black;
}
.cartHead ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  gap: 30%;
  font-size: 1vw;
}
.cart .checkout {
  width: 92.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5%;
  font-family: romia;
}
.checkout button {
  width: 8%;
  padding: 1% 0% 1% 0%;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  font-size: 1vw;
}
.viewMore {
  width: 8%;
  padding: 1% 0% 1% 0%;
  background-color: transparent;
  border: 1px solid black;
  font-family: rena;
  position: relative;
  left: 93%;
  font-size: 1vw;
}
.checkout button:hover {
  background-color: #000000;
  color: white;
}

/* FOOTER */

footer {
  background-color: #000000;
  position: relative;
  padding: 2% 2% 0.5% 2%;
  color: white;
  height: 33vw;
}
.footerContent {
  width: 99.9%;
  height: 90%;
  border: 2px solid white;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  font-family: sans-serif;
  align-items: center;
}
.footerInfo {
  width: 70%;
  height: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  left: 5%;
}
footer span {
  display: flex;
  align-items: center;
}
footer span img {
  width: 1em;
  height: 1em;
}
.footerLogo {
  font-size: small;
  width: 20%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  position: relative;
  line-height: 1.7vw;
}
.footerLogo p {
  font-size: 1vw;
}
.ftrLogo {
  width: 80%;
}
.socials {
  width: 65%;
  position: relative;
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 5%;
}
.socials a img {
  position: relative;
  width: 3.5vw;
}
.footerLogotxt {
  position: relative;
}

.qLinks a {
  text-decoration: none;
  color: white;
  margin-bottom: 22%;
  font-size: 1.5vw;
}
.qLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.office span img {
  width: 1.9vw;
  height: 1.9vw;
  margin-right: 25px;
}
.office span {
  margin-bottom: 2vw;
  font-size: 1.5vw;
}
footer h3 {
  font-family: rena;
  font-size: 1vw;
}

.subscribe input {
  padding: 0.5vw;
  font-family: rena;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin-right: 15px;
  width: 11vw;
  font-size: 1vw;
}

.subscribe button {
  padding: 0.6vw;
  width: 6vw;
  font-family: rena;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 0.8vw;
}
.subscribe h3 {
  margin-bottom: 0;
}
.subscribe p {
  line-height: 1.9vw;
  font-size: 1vw;
}

.office span,
.qLinks a {
  font-size: 1.2vw;
}
.office,
.qLinks,
.subscribe {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 55%;
}
.footerEnd {
  display: flex;
  justify-content: space-between;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1vw;
  margin: 0% 2% 0% 0%;
}
.footerEnd u {
  cursor: pointer;
}
.footerSocial {
  width: 2.5%;
  display: flex;
  gap: 1.3vw;
  position: relative;
  right: 1%;
}
.footerSocial a img {
  width: 2vw;
  height: 2vw;
}
.qLinks h3,
.office h3,
.subscribe h3 {
  font-size: 1.2vw;
}
/* Phone Footer */
.phoneFooter {
  background-color: #000000;
  padding: 5%;
}
.ftrLogoPhone {
  width: 50%;
}
.phoneFooterSubscribe {
  color: white;
  font-family: rena;
}
.phoneFooterSubscribe h3 {
  font-size: 5vw;
}
.phoneFooterSubscribe p {
  font-size: 3vw;
  line-height: 6vw;
}
.phoneFooterSubscribe input {
  width: 50vw;
  height: 8vw;
  font-size: 2.5vw;
  padding: 5px 5px 5px 10px;
}
.phoneFooterSubscribe button {
  width: 30%;
  height: 10vw;
  font-size: 2.5vw;
  position: relative;
}
.phoneFooterSubscribe .form {
  display: flex;
  height: 5vw;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 3%;
}
.phoneFooterSubscribe ::placeholder {
  color: white;
}
.phoneFooterLinks {
  display: flex;
}
.phoneFooterLinks .qLinks {
  color: white;
  width: 40%;
}
.phoneFooterLinks .qLinks h3 {
  font-size: 5vw;
}
.phoneFooterLinks .qLinks a {
  font-size: 4vw;
  margin-bottom: 3vw;
}
.phoneFooterLinks .office {
  color: white;
  width: 60%;
  margin-left: 3%;
}
.phoneFooterLinks .office h3 {
  font-size: 5vw;
  margin-left: 3%;
}
.phoneFooterLinks .office span {
  font-size: 4vw;
  margin-bottom: 7vw;
  width: fit-content;
}
.phoneFooterLinks .office img {
  width: 4vw;
  height: 4vw;
  margin-right: 4vw;
}
.phoneFooterSocial .socials {
  width: 40vw;
}
.phoneFooterSocial .socials img {
  width: 13vw;
}
.phoneFooterEnd .footerEnd {
  margin-top: 5%;
  color: white;
  font-size: 3vw;
  display: flex;
  flex-direction: column;
}
/* EXTRA */
.hide {
  display: none;
}
.clear {
  background-color: transparent;
}

/* ANIMATIONS */

/* Fade-in Animation */
.fade-in {
  opacity: 0;
  transition: opacity 3s ease-out;
}

.fade-in.visible {
  opacity: 1;
}

/* Slide-in Animation */
.slide-in {
  transform: translateY(20px);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.slide-in.visible {
  transform: translateY(0);
  opacity: 1;
}

/* Floating Animation */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Adjust the value for more/less floating */
  }
  100% {
    transform: translateY(0);
  }
}

.floating {
  animation: float 1s ease-in-out infinite; /* Increased duration for slower floating */
}
.items:hover {
  animation: float 2s ease-in-out infinite; /* Increased duration for slower floating */
}

.floating-on-hover {
  animation: float 1s ease-in-out infinite; /* Ensure continuous floating */
}

.floating-on-hover:hover {
  animation-play-state: paused; /* Pause the floating animation on hover */
}

/* MOBILE VIEWQ */

@media (max-width: 700px) {
  ::-webkit-scrollbar {
    width: 0; /* Adjust the width as needed */
  }

  .phoneNav {
    padding: 5% 5% 5% 5%;
  }
  .navBg h1 {
    font-size: 50vw;
    line-height: 100%;
    margin-bottom: 0;
  }
  .phoneLogo {
    width: 28vw;
    height: 12vw;
  }
  .navBg p {
    margin-top: 0;
    font-size: 2.5vw;
  }
  #exploreAll {
    display: none;
  }
  .product {
    padding: 10% 8% 1% 10.5%;
  }
  .product .head {
    width: 100%;
    margin-bottom: 9vw;
  }
  .product .head h3 {
    font-size: 5.2vw;
    margin-bottom: 3vw;
  }
  .product .head p {
    font-size: 2.8vw;
    font-weight: 50;
  }
  .productRow {
    flex-direction: column;
  }
  .product .head span {
    position: relative;
    left: 1.5%;
  }
  .items {
    width: 80vw;
    height: 100vw;
    margin-bottom: 35%;
  }
  .bgcolor {
    background-color: rgb(242 231 223);
    width: 100vw;
    height: 28vh;
    position: absolute;
    top: 50%;
    right: -9.5%;
    z-index: -1;
  }
  .items p {
    font-size: 3vw;
  }
  .itemDetails h5 {
    font-size: 3.5vw;
    margin-top: 2vh;
    margin-bottom: 8px;
  }
  .items img {
    height: 40vh;
    object-fit: cover;
  }
  .items button {
    height: 15vw;
    font-size: 3vw;
  }
  .testimony {
    height: fit-content;
  }
  .testimony .head {
    margin-bottom: 2%;
  }
  .testimony .head h3 {
    font-size: 5.5vw;
  }
  .testimony .head p {
    font-size: 2.5vw;
  }
  .testifoot {
    gap: 4%;
  }
  .testifoot img {
    margin-top: 2.7vw;
    width: 10vw;
    height: 10vw;
  }
  .scroll .dot {
    height: 8px;
    width: 8px;
  }
  .contact {
    padding: 5% 2% 10% 2%;
    justify-content: flex-start;
    height: fit-content;
  }

  .contact .head {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    align-items: center;
    margin-bottom: 5%;
  }
  .contact .head h3 {
    font-family: romia;
    font-size: 5vw;
    margin-bottom: 10px;
  }
  .contact .head p {
    font-family: rena;
    font-size: 2vw;
  }
  .contact .body {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .contact .body .form {
    width: 85%;
    margin-bottom: 7%;
  }

  .ContactInput {
    height: 6vh;
    font-size: 2.5vw;
    margin-bottom: 3%;
  }
  .contact .body .form textarea {
    height: 60px;
    height: 15vh;
    font-size: 2.5vw;
    margin-bottom: 3%;
  }
  .contact .body .form button {
    width: 100%;
    height: 13vw;
    font-size: 0.8em;
    background-color: black;
    color: white;
    border: 1px solid black;
    font-family: rena;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  .contact .body .map {
    width: 85%;
    height: 50vw;
  }
  .contact .body .map iframe {
    width: 99.9%;
    height: 100%;
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .about .info {
    order: 2;
    width: 100%;
    height: fit-content;
  }
  .about .info h3 {
    font-size: 20px;
  }
  .about .info p {
    font-size: 12px;
    line-height: 20px;
    font-weight: 50;
  }
  .about .abtImage {
    order: 1;
    width: 100%;
    height: 400px;
    margin-bottom: 5%;
  }
  .testimony {
    padding-bottom: 9%;
    padding-top: 5%;
  }
  .testimonies {
    height: 55vw;
  }
  .Ctestimony {
    width: 70%;
    height: 35vw;
    padding: 8%;
  }
  .Ctestimony .star img {
    width: 5vw;
  }
  .Ctestimony p {
    font-size: 3vw;
    margin-bottom: 2px;
    line-height: 4vw;
  }
  .Ctestimony .testifoot h3 {
    font-size: 4vw;
  }
  .Ctestimony .testifoot p {
    font-size: 2.5vw;
  }
  .arrows img {
    width: 10vw;
    height: 10vw;
  }
  .scroll {
    width: fit-content;
    padding: 0% 2% 0% 2%;
    height: 0.1vw;
    border-radius: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .madHoney {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
    padding: 5%;
    margin-bottom: 15%;
  }
  .productImage {
    order: 1;
    margin-bottom: 5%;
    width: 80%;
    height: 35vh;
  }
  .madHoney .productDetail {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    height: 25vw;
  }
  .madHoney .productDetail .head {
    margin-bottom: 10px;
  }
  .madHoney .productDetail h2 {
    font-size: 4vw;
    font-family: romia;
    margin-top: 0;
  }
  .madHoney .productDetail p {
    font-size: 1.5vw;
    font-family: romia;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .madHoney h3,
  .madHoney h4 {
    font-family: romia;
    font-size: 2vw;
  }
  .quantity input {
    text-align: center;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;
    width: 7vw;
    text-align: center;
  }
  .quantity h4 {
    margin-bottom: 5px;
  }
  .productDetail .quantity img {
    width: 5vw;
    cursor: pointer;
  }
  .quantity .input {
    display: flex;
    align-items: center;
    gap: 2%;
  }
  #addToCart {
    font-family: rena;
    font-size: 2vw;
    width: 100%;
    height: 5vw;
    padding: 1vw;
    background-color: transparent;
    border: 1px solid black;
  }
}
@media (max-width: 750px) {
  #nav3Btn {
    width: 25px;
    height: 25px;
  }
  .navBg h1 {
    font-size: 13vw;
    line-height: 140%;
    margin-bottom: 0;
  }
  .navBg p {
    margin-top: 0;
    font-size: 4vw;
  }
  .ftrLogoPhone {
    width: 30%;
  }
  .phoneFooterSubscribe h3 {
    font-size: 4vw;
  }
  .phoneFooterSubscribe p {
    font-size: 2vw;
    line-height: 4vw;
  }
  .phoneFooterSubscribe input {
    width: 50vw;
    height: 6vw;
    font-size: 2.5vw;
    padding: 5px 5px 5px 10px;
  }
  .phoneFooterSubscribe button {
    width: 30%;
    height: 8vw;
    font-size: 2.5vw;
    position: relative;
  }
  .phoneFooterSubscribe .form {
    display: flex;
    height: 5vw;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 3%;
  }
  .phoneFooterSubscribe ::placeholder {
    color: white;
  }
  .phoneFooterLinks .qLinks {
    color: white;
    width: 40%;
  }
  .phoneFooterLinks .qLinks h3 {
    font-size: 4vw;
  }
  .phoneFooterLinks .qLinks a {
    font-size: 3vw;
    margin-bottom: 3.5vw;
  }
  .phoneFooterLinks .office {
    color: white;
    width: 60%;
    margin-left: 3%;
  }
  .phoneFooterLinks .office h3 {
    font-size: 4vw;
    margin-left: 3%;
  }
  .phoneFooterLinks .office span {
    font-size: 3vw;
    margin-bottom: 5vw;
    width: fit-content;
  }
  .phoneFooterLinks .office img {
    width: 4vw;
    height: 4vw;
    margin-right: 4vw;
  }
  .phoneFooterSocial .socials {
    width: 35vw;
  }
  .phoneFooterSocial .socials img {
    width: 10vw;
  }
  .phoneFooterEnd .footerEnd {
    margin-top: 5%;
    color: white;
    font-size: 2vw;
    display: flex;
    flex-direction: column;
  }
  .question .head h3 {
    margin-bottom: 2%;
    font-size: 3.7vw;
  }
  .question .head p {
    font-size: 2vw;
  }
  .questions .left img {
    width: 5vw;
    height: 5vw;
  }
  .questions .left .details {
    line-height: 1px;
  }
  .questions .left .details p {
    font-size: 2vw;
  }
  .questions .right p {
    font-size: 2vw;
    line-height: 15px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.35;
  }
  75% {
    opacity: 0.55;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

/* Sticky */
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  padding-left: 5vw;
  width: 92vw;
  animation: fadeIn 0.6s ease-in-out forwards; /* Apply the fade-in animation */
}
.sticky:hover {
  background-color: rgba(0, 0, 0, 1);
}
